.value {
  flex: 1;
  overflow-wrap: anywhere;
  margin-right: 38px;
}

.label {
  width: 85px;
  margin-right: 8px;
  color: var(--white2);
}