@import 'styles/layers.scss';

@layer component {
  .btn {
    align-self: center;
    margin-top: 30px;
    padding: 10px 55px;
  }
  
  .title-wrap {
    margin-bottom: 30px;
  }
  
  .title {
    font-size: 20px;
  }

  .wrap {
    height: 100%;
    overflow-y: auto;
    padding: 22px;
    position: relative;
  }
  
  .item, .item-download {
    margin-bottom: 8px;
  }
  
  .item-download {
    align-items: center;
  }
  
  .value-download {
    flex: none !important;
    margin-right: 8px !important;
  }
  
  .copy-btn-icon {
    color: var(--white);
    align-self: flex-start;
  }  

  .divider {
    margin: 20px 0;
  }
}

