@import 'styles/layers.scss';

@layer component {
  .modalDialog {
    min-width: 377px;
  }
  
  .modalBody {
    padding: 22px;
  }
}

