@import 'styles/layers.scss';

@layer component {
  .header {
    font-size: 20px;
  }
  
  .text {
    font-size: 12px;
    margin: 20px 0 30px;
  }
  
  .btn {
    width: 94px;
    height: 41px;
  }
}
